import revive_payload_client_lUPXSSIU9h from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ATF27Ko9WD from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8CFvXHLmjF from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E0SrsEEsWN from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qRHZZLepNw from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dnN6bZG8fs from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_l6WZE2wscy from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_rsHpHNwPo0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hpdAyrvUDP from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/disputes/.nuxt/components.plugin.mjs";
import prefetch_client_C1GxY6gQjo from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.0_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_encoding_n7hwt2pzbjkx7skmqfyhwbvnhe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/disputes/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/workflows/console-ui/packages/protected/src/runtime/plugin.ts";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_MwzO4yGucH from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typesc_gummvr5zvfnqdrgh7t4kjut3l4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_lUPXSSIU9h,
  unhead_ATF27Ko9WD,
  router_8CFvXHLmjF,
  payload_client_E0SrsEEsWN,
  navigation_repaint_client_qRHZZLepNw,
  check_outdated_build_client_dnN6bZG8fs,
  view_transitions_client_l6WZE2wscy,
  chunk_reload_client_rsHpHNwPo0,
  plugin_vue3_hpdAyrvUDP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_C1GxY6gQjo,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_PXQeuxRKoc,
  plugin_MwzO4yGucH
]